import axios from "axios";
import { ResponseData, ResponseTables } from "../models/response_model";

class TableService {
  async getData(): Promise<ResponseTables> {
    let result: ResponseTables = {};

    await axios
      .post("tabla")
      .then((response) => {
        let res = response.data.response;
        if (res === "failed") {
          result.code = "logout";
        } else {
          result.code = "success";
        }
        result.data = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateSIM(phone?: string, active?: boolean): Promise<ResponseData> {
    let result: ResponseData = {};

    await axios
      .post(`bloqueo_sim`, {
        phone: phone,
        active: active,
      })
      .then((response) => {
        let res = response.data.response;
        if (res === "failed") {
          result.code = "logout";
        } else {
          result.code = res;
        }
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async downloadData(month?: string, year?: string): Promise<ResponseData> {
    let result: ResponseData = {};

    await axios
      .post(
        "tabla_export",
        {
          month: month,
          year: year,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        let res = response.data.response;
        if (res === "failed") {
          result.code = "logout";
        } else {
          result.code = res;
        }
        result.data = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default TableService;
