import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MuiForm } from "react-mui-form-validator";
import { BlocBuilder } from "react-stream-bloc";
import UiLoadingButton from "src/components/LoadingButtons";
import { UiTextField } from "src/components/TextField";
import { AuthState } from "src/core/bloc/auth/auth_state";
import BoxedLayout from "src/core/components/BoxedLayout";
import { useAuthBloc } from "src/core/provider/Provider";

const Login = () => {
  const bloc = useAuthBloc();
  const { t } = useTranslation();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [code, setCode] = useState<string>();

  const handleLogin = async () => {
    setCode(undefined);
    const res = await bloc.login(username, password);
    if (res !== "success") {
      if (res) {
        setCode(res);
      }
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid item xs={12} sm={8} md={5} component={Paper} square>
        <BoxedLayout>
          <Typography component="h1" variant="h3">
            Iniciar sesión
          </Typography>
          <BlocBuilder
            bloc={bloc}
            builder={(state: AuthState) => (
              <Box marginTop={1}>
                <MuiForm
                  onSubmit={handleLogin}
                  style={{
                    maxWidth: 320,
                    width: "100vw",
                    padding: 10,
                  }}
                >
                  {code ? (
                    <div
                      style={{
                        color: "white",
                        backgroundColor: "#FF0033",
                        width: "100%",
                        opacity: 0.8,
                        marginTop: 15,
                        marginBottom: 10,
                        textAlign: "center",
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderRadius: 15,
                      }}
                    >
                      {t(`error.${code}`)}
                    </div>
                  ) : undefined}
                  <UiTextField
                    value={username}
                    onChange={(v) => {
                      setCode(undefined);
                      setUsername(v.target.value);
                    }}
                    label="Usuario"
                    placeholder="cuevas"
                    fullWidth
                    disabled={state.login}
                    validators={[
                      {
                        validator: "required",
                      },
                    ]}
                    errorMessage={["Ingrese su correo electrónico"]}
                  />
                  <UiTextField
                    value={password}
                    onChange={(v) => {
                      setCode(undefined);
                      setPassword(v.target.value);
                    }}
                    label="Contraseña"
                    placeholder="*******"
                    fullWidth
                    type="password"
                    disabled={state.login}
                    validators={[
                      {
                        validator: "required",
                      },
                    ]}
                    errorMessage={"Ingrese su contraseña"}
                    sx={{
                      mt: 1,
                    }}
                  />
                  <UiLoadingButton
                    type="submit"
                    loading={state.login}
                    text="Iniciar sesión"
                    fullWidth
                    sx={{
                      mt: 3,
                    }}
                  />
                </MuiForm>
              </Box>
            )}
          />
        </BoxedLayout>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(./img/startup.png)",
          backgroundRepeat: "no-repeat",
          bgcolor: "background.default",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </Grid>
  );
};

export default Login;
