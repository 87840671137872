import { createContext } from "react-stream-bloc";
import { UserBloc } from "../bloc/user/user_bloc";
import { AuthBloc } from "../bloc/auth/auth_bloc";
import { LineBloc } from "../bloc/line/line_bloc";
import { UserLineBloc } from "../bloc/user_line/user_line_bloc";
import { TableBloc } from "../bloc/table/table_bloc";

const [authContext, useAuth] = createContext<AuthBloc>();
const useAuthBloc = useAuth;

export { authContext, useAuthBloc };

const [userContext, useUser] = createContext<UserBloc>();
const useUserBloc = useUser;

export { userContext, useUserBloc };

const [lineContext, useLine] = createContext<LineBloc>();
const useLineBloc = useLine;

export { lineContext, useLineBloc };

const [userLineContext, useUserLine] = createContext<UserLineBloc>();
const useUserLineBloc = useUserLine;

export { userLineContext, useUserLineBloc };

const [tableContext, useTable] = createContext<TableBloc>();
const useTableBloc = useTable;

export { tableContext, useTableBloc };