import * as React from "react";
import { useStyles } from "./styles";
import { Box, SxProps, Theme, Typography } from "@mui/material";

type LabelProps = {
  text?: string;
};

export const UiLabel = ({ text }: LabelProps) => {
  const classes = useStyles();

  return <div className={classes.textBox}>{text}</div>;
};

type TextProps = {
  children?: React.ReactNode;
  label?: string | React.ReactNode;
  error?: string | React.ReactNode;
  text?: string | number;
  subText?: string | number;
  sx?: SxProps<Theme> | undefined;
  direction?: "column" | "row";
  fontSize?: number;
  opacity?: number;
  color?: string;
};

export const TextLabel = ({
  text,
  children,
  error,
  label,
  subText,
  sx,
  direction,
  fontSize,
  opacity,
  color,
}: TextProps) => {
  return (
    <Box display="flex" flexDirection={direction ?? "column"} sx={sx}>
      {error !== undefined ? (
        <Typography
          sx={{
            fontSize: fontSize ?? 16,
            opacity: opacity,
            color: color,
          }}
          color="error.main"
        >
          {error}
        </Typography>
      ) : (
        <></>
      )}
      {label !== undefined ? (
        <Typography
          sx={{ fontSize: fontSize ?? 14, opacity: opacity, color: color }}
          color="text.label"
        >
          {label}
        </Typography>
      ) : (
        <></>
      )}
      {text !== undefined ? (
        <Typography
          variant="h6"
          color="text.text"
          sx={{
            fontSize: fontSize,
            marginLeft: direction === "row" ? 1 : undefined,
            opacity: opacity,
            color: color,
          }}
        >
          {text}
        </Typography>
      ) : (
        <></>
      )}
      {subText !== undefined ? (
        <Typography
          variant="h6"
          color="text.text"
          sx={{
            fontSize: fontSize,
            opacity: opacity,
            color: color,
          }}
        >
          {subText}
        </Typography>
      ) : (
        <></>
      )}
      {children}
    </Box>
  );
};
