import Container from "src/components/Container";
import UiButton from "src/components/Buttons";
import { useEffect } from "react";
import { BlocBuilder } from "react-stream-bloc";
import EntryList from "./components/List";
import {
  useAuthBloc,
  useTableBloc,
} from "src/core/provider/Provider";
import { TableState } from "src/core/bloc/table/table_state";

const EntryPage = () => {
  const bloc = useTableBloc();
  const blocAuth = useAuthBloc();

  const initData = async () => {
    if (bloc.data.length === 0) {
      const res = await bloc.getData();
      if (res === "logout") {
        blocAuth.logout();
      }
    }
  };

  const handleRefresh = async () => {
    const res = await bloc.getData();
    if (res === "logout") {
      blocAuth.logout();
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state: TableState) => {
        return (
          <Container
            helmetTitle="tabla"
            title="Datos"
            toolbar={
              <UiButton
                text="Actualizar datos"
                onClick={handleRefresh}
                disabled={state.loading}
                variant="outlined-primary"
                sx={{ mr: 2 }}
              />
            }
          >
            <EntryList state={state} data={state.data} bloc={bloc} />
          </Container>
        );
      }}
    />
  );
};

export default EntryPage;
