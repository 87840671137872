import * as React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Button,
  DialogTitle,
  DialogProps,
  Typography,
  Box,
} from "@mui/material";

//Style
import useStyles from "./style";
import {
  UiDialogProps,
  MessageDialogProps,
  ResetDialogProps,
  ConfirmDialogProps,
} from "src/core/types/dialog_type";
import UiButton from "../Buttons";

export const UiDialog = (props: UiDialogProps) => {
  const classes = useStyles();
  const {
    children,
    title,
    open,
    setOpen,
    maxWidth,
    fullWidth,
    scroll,
    actions,
  } = props;

  const [max] = React.useState<DialogProps["maxWidth"]>(
    maxWidth === undefined ? "sm" : maxWidth
  );

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
      scroll={scroll}
      onClose={setOpen}
      className={classes.dialog}
      open={open}
      maxWidth={max}
      fullWidth={fullWidth}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        justifyItems="center"
        style={{
          width: "100%",
        }}
      >
        {title ? (
          <Typography
            variant="h5"
            sx={{
              mt: 2,
              ml: 3,
              mr: 3,
            }}
          >
            {title}
          </Typography>
        ) : undefined}
        {actions}
      </Box>
      {scroll === "paper" ? (
        children
      ) : (
        <Box
          sx={{
            padding: 2.5,
          }}
        >
          {children}
        </Box>
      )}
    </Dialog>
  );
};

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const classes = useStyles();
  const { open, setOpen, onClick, title, variant, actionText, actionLoading } = props;

  const handleClose = () => {
    setOpen!(false);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
      onClose={handleClose}
      className={classes.dialogDelete}
      open={open}
    >
      <DialogTitle>
        <div>{title}</div>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} textAlign="center">
            <UiButton text="Cancelar" variant="cancel" onClick={handleClose} />
          </Grid>
          <Grid item xs={6} sm={6} textAlign="center">
            <UiButton text={actionText} variant={variant} onClick={onClick} disabled={actionLoading}  />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const ResetDialog = (props: ResetDialogProps) => {
  const classes = useStyles();
  const { open, setOpen, onClick, title } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
      onClose={handleClose}
      className={classes.dialogDelete}
      open={open}
    >
      <Typography
        variant="h5"
        sx={{
          mt: 2,
          ml: 3,
          mr: 3,
        }}
      >
        {title}
      </Typography>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} textAlign="center">
            <UiButton text="Cancelar" variant="cancel" onClick={handleClose} />
          </Grid>
          <Grid item xs={6} sm={6} textAlign="center">
            <UiButton text="Restablecer" variant="delete" onClick={onClick} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const MessageDialog = (props: MessageDialogProps) => {
  const classes = useStyles();
  const { open, setOpen, message } = props;

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
      onClose={() => setOpen!(false)}
      className={classes.dialogDelete}
      open={open}
    >
      <DialogTitle>
        <div>{message}</div>
      </DialogTitle>

      <DialogContent>
        <Grid container justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonOk}
            onClick={() => setOpen!(false)}
          >
            Cerrar
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
