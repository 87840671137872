import Container from "src/components/Container";
import UiButton from "src/components/Buttons";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import TableService from "src/core/services/table_service";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAuthBloc } from "src/core/provider/Provider";

const months = [
  { value: 1, label: "Enero" },
  { value: 2, label: "Febrero" },
  { value: 3, label: "Marzo" },
  { value: 4, label: "Abril" },
  { value: 5, label: "Mayo" },
  { value: 6, label: "Junio" },
  { value: 7, label: "Julio" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Septiembre" },
  { value: 10, label: "Octubre" },
  { value: 11, label: "Noviembre" },
  { value: 12, label: "Diciembre" },
];

const ExportPage = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const blocAuth = useAuthBloc();

  const [month, setMonth] = useState<string>(currentMonth.toString());
  const [year, setYear] = useState<string>(currentYear.toString());
  const [loading, setLoading] = useState<boolean>(false);

  const downloadData = async () => {
    let m = month;
    const mN = Number(month);

    if (mN < 10) {
      m = `0${mN}`;
    }
    setLoading(true);
    const res = await new TableService().downloadData(m, year);

    if (res.code === "logout") {
      blocAuth.logout();
    } else {
      if (res.data) {
        // Crear un objeto Blob con la respuesta del servidor
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Crear una URL temporal para descargar el archivo
        const url = window.URL.createObjectURL(blob);

        // Crear un enlace de descarga y activarlo
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export_data.xlsx"); // Nombre del archivo descargado
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Eliminar el enlace del DOM después de la descarga
      }
    }
    setLoading(false);
  };

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 100; i--) {
      years.push(i);
    }
    return years;
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };

  const handleMonthChange = (event: SelectChangeEvent) => {
    setMonth(event.target.value as string);
  };

  return (
    <Container helmetTitle="Exportar" title="Exportar datos">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        justifyItems="center"
        style={{
          width: "100%",
        }}
      >
        <Grid container marginTop={2} spacing={1} marginRight={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Año</InputLabel>
              <Select value={year} label="Año" onChange={handleYearChange}>
                {generateYears().map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Mes</InputLabel>
              <Select value={month} label="Mes" onChange={handleMonthChange}>
                {months.map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <UiButton
          text="Exportar"
          onClick={downloadData}
          disabled={loading}
        />
      </Box>
    </Container>
  );
};

export default ExportPage;
