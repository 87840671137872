import { AuthBloc } from "../bloc/auth/auth_bloc";
import { LineBloc } from "../bloc/line/line_bloc";
import { TableBloc } from "../bloc/table/table_bloc";
import { UserBloc } from "../bloc/user/user_bloc";
import { UserLineBloc } from "../bloc/user_line/user_line_bloc";
import LineService from "../services/line_service";
import TableService from "../services/table_service";
import UserLineService from "../services/user_line_service";
import UserService from "../services/user_service";

export function providerAuthBloc(): AuthBloc {
  const bloc = new AuthBloc(new UserService()); //Init your values
  return bloc;
}

export function providerUserBloc(): UserBloc {
  const bloc = new UserBloc(new UserService()); //Init your values
  return bloc;
}

export function provideLineBloc(): LineBloc {
  const bloc = new LineBloc(new LineService()); //Init your values
  return bloc;
}

export function provideUserLineBloc(): UserLineBloc {
  const bloc = new UserLineBloc(new UserLineService()); //Init your values
  return bloc;
}

export function provideTableBloc(): TableBloc {
  const bloc = new TableBloc(new TableService()); //Init your values
  return bloc;
}