import * as React from "react";
import { LineListProps } from "src/core/types/list_type";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/RemoveRounded";
import { Line } from "src/core/models/line_model";
import { Box, Card, CircularProgress, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import Empty from "src/core/components/Empty";
import { TextLabel } from "src/components/Label";
import ContainerContent from "src/components/Container/Content";
import { LineState } from "src/core/bloc/line/line_state";
import { BlocBuilder } from "react-stream-bloc";
import InfiniteScroll from "react-infinite-scroll-component";

const LineList = ({ bloc }: LineListProps) => {
  const handleDelete = (line?: Line) => {
  };

  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state: LineState) => {
        return (
          <ContainerContent loading={state.loading}>
            {state.data.length !== 0 ? (
              <InfiniteScroll
                dataLength={state.data.length}
                next={() => { }}
                hasMore={state.hasMore ? false : true} // Replace with a condition based on your data source
                loader={<></>}
              >
                <Grid
                  container
                  display="flex"
                  flexWrap="wrap"
                  spacing={2}
                  marginTop={2}
                >
                  {state.data.map((item: Line, index) => {
                    return (
                      <Grid key={index} item>
                        <Card>
                          <Stack direction="row">
                            <Grid
                              container
                              direction="row"
                              spacing={2}
                              padding={2}
                            >
                              <Grid item xs={8}>
                                <TextLabel label="Id" text={item.line_id} />
                              </Grid>
                              <Grid item xs={4}>
                                <TextLabel
                                  label="Usado"
                                  text={item.used ? "Si" : "No"}
                                />
                              </Grid>
                            </Grid>
                            {item.used ? (
                              <div style={{ width: 40 }} />
                            ) : (
                              <IconButton
                                id="line-button"
                                aria-label="actions"
                                style={{
                                  maxHeight: 45,
                                  maxWidth: 45,
                                }}
                                disabled={state.loading}
                                onClick={(v) => handleDelete(item)}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            )}
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
                {state.hasMore ? (
                  <Box
                    alignItems="center"
                    justifyItems="center"
                    textAlign="center"
                    marginTop={1}
                    marginBottom={1}
                  >
                    <CircularProgress size={27} />
                  </Box>
                ) : undefined}
              </InfiniteScroll>
            ) : (
              <Empty title="Aún no tienes IDS de linea" />
            )}
          </ContainerContent>
        );
      }}
    />
  );
};

export default LineList;
