import axios from "axios";
import { ResponseLine, ResponseLines } from "../models/response_model";
import { Line } from "../models/line_model";

class LineService {
  async getLines(filter?: any): Promise<ResponseLines> {
    let result: ResponseLines = {};

    await axios
      .get("lines", {
        params: filter,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async searchLines(query: string) {
    let result: ResponseLines = {};

    await axios
      .get(`lines/search`, {
        params: {
          query: query,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async cancelLine(data: any) {
    let result: ResponseLines = {};

    await axios
      .post(`line/cancel`, {
        numero: data.numero,
        referencia: data.referencia,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async resetLines() {
    let result: ResponseLines = {};

    await axios
      .put(`reset/lines`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default LineService;
