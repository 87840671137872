import { Bloc } from "react-stream-bloc";
import { TableInitialState, TableState } from "./table_state";
import TableService from "src/core/services/table_service";
import { Table } from "src/core/models/table_model";

export class TableBloc extends Bloc<TableState> {
  data: Table[] = [];
  data_total: number = 0;

  constructor(private service: TableService) {
    super(TableInitialState);
  }

  async getData(): Promise<string | undefined> {
    this.changeState({ ...this.state, loading: true });

    const response = await this.service.getData();
    if (response.code === "success") {
      this.mapToLoadedState(response.data ?? []);
    } else {
      this.changeState({ ...this.state, loading: false });
    }
    return response.code;
  }

  async updateSIM(phone?: string, active?: boolean): Promise<string | undefined> {
    const res = await this.service.updateSIM(phone, active);

    if (res.code === "success") {
      const newItems = this.data.map((oldItem) => {
        if (oldItem.phone === phone) {
          return {
            ...oldItem,
            sim_active: !active,
          };
        } else {
          return oldItem;
        }
      });
      this.mapToLoadedState(newItems);
      return res.code;
    } else {
      return res.code;
    }
  }

  mapToLoadedState(data: Table[], total?: number) {
    this.data = data ?? [];
    this.data_total = total ?? 0;
    this.changeState({
      ...this.state,
      loading: false,
      data: data,
      data_total: total ?? 0,
    });
  }
}
