import { Table } from "src/core/models/table_model";

export type TableState = {
  loading: boolean | false;
  loadingOutlets: boolean;
  hasMore: boolean;
  data: Table[] | [];
  filteredData: Table[];
  data_total: number;
  searching: boolean;
  code?: string;
  message?: string;
};

export const TableInitialState: TableState = {
  loading: false,
  loadingOutlets: false,
  hasMore: false,
  searching: false,
  data: [],
  filteredData: [],
  data_total: 0,
};
