import { Bloc } from "react-stream-bloc";
import { UserInitialState, UserState } from "./user_state";
import UserService from "src/core/services/user_service";
import { User } from "src/core/models/user_model";

export class UserBloc extends Bloc<UserState> {
  users: User[] = [];
  filterUsers: User[] = [];

  constructor(private service: UserService) {
    super(UserInitialState);
    this.getAuth();
  }

  async getUsers() {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.getUsers();

    if (response.code === "success") {
      this.changeState(this.mapToLoadedState(response.data ?? []));
    } else {
      this.changeState(this.mapToLoadedState(this.users));
    }
  }

  async searchUsers(query: string) {
    if (query !== "" && query !== undefined && query !== null) {
      this.changeState({ ...this.state, searching: true });
      const filtered = this.users.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val === "string" && val.includes(query)
        )
      );

      if (filtered.length === 0) {
        const res = await this.service.searchUsers(query);
        if (res.code === "success") {
          const resData = res.data;
          if (resData) {
            const newData: User[] = this.users;
            await Promise.all(
              resData.map((v) => {
                const existedItem = newData.find((i) => i.uid === v.uid);

                if (!existedItem) {
                  newData.push(v);
                }
                return true;
              })
            );
            this.changeState({ ...this.state, filtered: res.data ?? [] });
            this.changeState(this.mapToLoadedState(newData));
          }
        }
      } else {
        this.changeState({
          ...this.state,
          filtered: filtered,
          searching: false,
        });
      }
    } else {
      this.changeState({ ...this.state, filtered: [] });
    }
  }

  async getNextUsers() {
    this.changeState({ ...this.state, hasMore: true });
    const params = {
      skip: this.users.length,
    };
    const response = await this.service.getUsers(params);

    if (response.code === "success") {
      const resData = response.data;
      if (resData) {
        const newData: User[] = this.users;
        await Promise.all(
          resData.map((v) => {
            const existedItem = newData.find((i) => i.uid === v.uid);

            if (!existedItem) {
              newData.push(v);
            }
            return true;
          })
        );
        this.changeState(this.mapToLoadedState(newData));
      } else {
        this.changeState({ ...this.state, hasMore: false });
      }
    } else {
      this.changeState({ ...this.state, hasMore: false });
    }
  }

  async getAuth() {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.getUserData();

    if (response.code === "success") {
      this.changeState(this.mapToLoadedState([]));
    } else {
      this.changeState(this.mapToLoadedState(this.users));
    }
  }

  async addUser(data: User): Promise<string | undefined> {
    this.changeState({ ...this.state, adding: true });
    const response = await this.service.addUser(data);

    if (response.code === "success") {
      const user = response.data;
      if (user) {
        const newUSers = [...this.users, user];

        this.changeState(this.mapToLoadedState(newUSers));
      } else {
        this.changeState({ ...this.state, adding: false });
      }
    } else {
      this.changeState(this.mapToLoadedState(this.users));
    }
    return response.code;
  }

  async updateUser(data: User): Promise<string | undefined> {
    const response = await this.service.updateUser(data);
    if (response.code === "success") {
      const User = response.data;
      const newUsers = this.users.map((oldUser) => {
        if (oldUser.uid === data.uid) {
          return {
            ...oldUser,
            name: User?.name,
            lastname: User?.lastname,
            address: User?.address,
            contact_person: User?.contact_person,
            nif: User?.nif,
            phone: User?.phone,
            picture_url: User?.picture_url,
            username: User?.username,
            updated_at: User?.updated_at,
          };
        } else {
          return oldUser;
        }
      });
      this.changeState(this.mapToLoadedState(newUsers));
      return response.code;
    } else {
      this.changeState(this.mapToLoadedState(this.users));
      return response.code;
    }
  }

  async updateStatusUser(
    uid?: string,
    status?: string
  ): Promise<string | undefined> {
    const response = await this.service.updateStatusUser(uid, status);
    if (response.code === "success") {
      const User = response.data;
      const newUsers = this.users.map((oldUser) => {
        if (oldUser.uid === uid) {
          return {
            ...oldUser,
            status: User?.status,
            updated_at: User?.updated_at,
          };
        } else {
          return oldUser;
        }
      });
      this.changeState(this.mapToLoadedState(newUsers));
      return response.code;
    } else {
      this.changeState(this.mapToLoadedState(this.users));
      return response.code;
    }
  }

  async deleteUser(uid?: string): Promise<string | undefined> {
    this.changeState({ ...this.state, deleting: true });
    if (uid) {
      const res = await this.service.deleteUser(uid);
      if (res.code === "success") {
        const newUsers = this.users.filter((i) => i.uid !== uid);
        this.changeState(this.mapToLoadedState(newUsers));
      } else {
        this.changeState(this.mapToLoadedState(this.users));
      }
      return res.code;
    } else {
      return undefined;
    }
  }

  mapToLoadedState(data: User[]): UserState {
    this.users = data;
    return {
      loading: false,
      adding: false,
      deleting: false,
      updating: false,
      hasMore: false,
      searching: false,
      data: data,
      filtered: data,
    };
  }
}
