import React from "react";
import AppRoutes from "./AppRoutes";
import Loader from "./core/components/Loader";
import QueryWrapper from "./core/components/QueryWrapper";
import SettingsProvider from "./core/contexts/SettingsProvider";
import SnackbarProvider from "./core/contexts/SnackbarProvider";
import { BlocProvider } from "react-stream-bloc";
import {
  provideLineBloc,
  provideTableBloc,
  provideUserLineBloc,
  providerAuthBloc,
  providerUserBloc,
} from "./core/provider/DependenciesProvider";
import {
  userContext,
  authContext,
  lineContext,
  userLineContext,
  tableContext,
} from "./core/provider/Provider";

function App() {
  return (
    <React.Suspense fallback={<Loader />}>
      <SettingsProvider>
        <QueryWrapper>
          <SnackbarProvider>
            <BlocProvider
              providers={[
                <authContext.Provider value={providerAuthBloc()} />,
                <userContext.Provider value={providerUserBloc()} />,
                <lineContext.Provider value={provideLineBloc()} />,
                <userLineContext.Provider value={provideUserLineBloc()} />,
                <tableContext.Provider value={provideTableBloc()} />,
              ]}
            >
              <AppRoutes />
            </BlocProvider>
          </SnackbarProvider>
        </QueryWrapper>
      </SettingsProvider>
    </React.Suspense>
  );
}

export default App;
