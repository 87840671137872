import { useTranslation } from "react-i18next";
import { useState } from "react";
import { BlocBuilder } from "react-stream-bloc";
import { MuiForm } from "react-mui-form-validator";
import { UiTextField } from "src/components/TextField";
import { User } from "src/core/models/user_model";
import { Box, Chip, Grid, IconButton, Stack } from "@mui/material";
import UiLoadingButton from "src/components/LoadingButtons";
import UiButton from "src/components/Buttons";
import { UserBloc } from "src/core/bloc/user/user_bloc";
import { UserState } from "src/core/bloc/user/user_state";
import { useSnackbar } from "src/core/contexts/SnackbarProvider";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface AddContentProps {
  setOpen: (value: boolean) => void;
  bloc: UserBloc;
}

const AddContent = ({ setOpen, bloc }: AddContentProps) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [useSymbols, setUseSymbols] = useState(true);
  const [useNumbers, setUseNumbers] = useState(true);
  const [useLowerCase, setUseLowerCase] = useState(true);
  const [useUpperCase, setUseUpperCase] = useState(true);
  const [passwordLength, setPasswordLength] = useState(8);
  const [visibility, setVisibility] = useState<boolean>(false);

  const [code, setCode] = useState<string>();
  const snackbar = useSnackbar();

  const handleAdd = async () => {
    const data: User = {
      name: name,
      email: email,
      username: email,
      password: password,
    };

    const res = await bloc.addUser(data);
    if (res === "success") {
      setOpen(false);
      snackbar.success(`El usuario ${name} se ha agregado correctamente!`);
    } else {
      setCode(t(`error.${res}`));
    }
  };

  const handleGenerate = () => {
    let charset = "";
    let newPassword = "";

    if (useSymbols) charset += "!@#$%^&*()";
    if (useNumbers) charset += "0123456789";
    if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
    if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let i = 0; i < passwordLength; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    setPassword(newPassword);
  };

  const handleView = () => {
    if (visibility) {
      setVisibility(false);
    } else {
      setVisibility(true);
    }
  };

  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state: UserState) => (
        <Box marginTop={1}>
          <MuiForm
            onSubmit={handleAdd}
            style={{
              maxWidth: 320,
              width: "100vw",
            }}
          >
            {code ? (
              <div
                style={{
                  color: "white",
                  backgroundColor: "#FF0033",
                  width: "100%",
                  opacity: 0.8,
                  marginTop: 15,
                  marginBottom: 10,
                  textAlign: "center",
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 15,
                  paddingRight: 15,
                  borderRadius: 15,
                }}
              >
                {t(`error.${code}`)}
              </div>
            ) : undefined}
            <UiTextField
              value={name}
              onChange={(v) => setName(v.target.value)}
              label="Nombre"
              placeholder="Cuevas"
              fullWidth
              required
              errorMessage="Ingrese un nombre"
            />

            <UiTextField
              value={email}
              onChange={(v) => setEmail(v.target.value)}
              label="Correo electrónico"
              placeholder="ejemplo@cuevas.com"
              required
              fullWidth
              errorMessage={["Ingrese su correo electrónico"]}
              sx={{
                mt: 1,
              }}
            />

            <Stack direction="row" alignItems="center">
              <UiTextField
                value={password}
                onChange={(v) => setPassword(v.target.value)}
                label="Contraseña"
                placeholder="*******"
                type={visibility ? "text" : "password"}
                fullWidth
                required
                errorMessage={"Ingrese una contraseña"}
                sx={{
                  mt: 1,
                }}
              />
              <IconButton
                aria-label="ver"
                size="medium"
                sx={{
                  mt: 3,
                }}
                onClick={handleView}
              >
                <VisibilityIcon fontSize="medium" />
              </IconButton>
              <Chip
                label="Generar"
                onClick={handleGenerate}
                sx={{
                  mt: 3,
                }}
              />
            </Stack>

            <Grid container paddingTop={3} spacing={2}>
              <Grid item xs={6} textAlign="center">
                <UiButton
                  fullWidth
                  variant="outlined"
                  text="Cancelar"
                  onClick={() => setOpen(false)}
                  disabled={state.adding}
                />
              </Grid>
              <Grid item xs={6} textAlign="center">
                <UiLoadingButton
                  fullWidth
                  text={"Agregar"}
                  type="submit"
                  disabled={state.adding}
                />
              </Grid>
            </Grid>
          </MuiForm>
        </Box>
      )}
    />
  );
};

export default AddContent;
