import * as React from "react";
import {
  Box,
  InputBase,
  Paper,
  Table as TablePage,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
  IconButton,
  TableSortLabel,
} from "@mui/material";
import Empty from "src/core/components/Empty";
import ContainerContent from "src/components/Container/Content";
import Moment from "react-moment";
import { useEffect, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useStyles } from "src/admin/components/styles/toolbar";
import { TableState } from "src/core/bloc/table/table_state";
import { TableBloc } from "src/core/bloc/table/table_bloc";
import { Table } from "src/core/models/table_model";
import { ConfirmDialog } from "src/components/Dialog";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/BlockOutlined";
import { useSnackbar } from "src/core/contexts/SnackbarProvider";
import TableService from "src/core/services/table_service";
import { useAuthBloc } from "src/core/provider/Provider";
import { getComparator, Order } from "src/core/utils/table";
import { visuallyHidden } from "@mui/utils";

interface EntryListProps {
  state: TableState;
  bloc: TableBloc;
  data: Table[];
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Table;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "phone",
    numeric: false,
    disablePadding: true,
    label: "Número",
  },
  {
    id: "iccid",
    numeric: false,
    disablePadding: false,
    label: "ICCID",
  },
  {
    id: "activated_at",
    numeric: false,
    disablePadding: false,
    label: "Fecha de activación",
  },

  {
    id: "total_gb",
    numeric: false,
    disablePadding: false,
    label: "Datos disponibles",
  },
  {
    id: "used_gb",
    numeric: false,
    disablePadding: false,
    label: "Datos gastados",
  },
  {
    id: "total_minutes",
    numeric: false,
    disablePadding: false,
    label: "Voz disponible",
  },
  {
    id: "used_minutes",
    numeric: false,
    disablePadding: false,
    label: "Voz emitida",
  },
  {
    id: "total_sms",
    numeric: false,
    disablePadding: false,
    label: "SMS disponibles",
  },
  {
    id: "used_sms",
    numeric: false,
    disablePadding: false,
    label: "SMS utilizados",
  },
  {
    id: "sim_active",
    numeric: false,
    disablePadding: false,
    label: "Estado",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Table
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Table) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="left">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable = ({ state, data, bloc }: EntryListProps) => {
  const blocAuth = useAuthBloc();

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Table>("phone");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [tables, setTables] = useState<Table[]>(data);
  const [search, setSearch] = useState<string>("");
  const [rowsTotal, setRowsTotal] = useState<number>(data.length);
  const [dialog, setDialog] = useState<boolean>(false);
  const [table, setTable] = useState<Table | undefined>(undefined);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [variant, setVariant] = useState<"contained" | "delete">("contained");
  const [dialogActionText, setDialogActionText] = useState<string>("Aceptar");
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);

  const classes = useStyles();
  const snackbar = useSnackbar();

  const handleChangeSearch = (v: any) => {
    setSearch(v.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Table
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleActiveBlock = (v: Table) => {
    setTable(v);

    if (v.sim_active) {
      setDialogTitle(
        `¿Estás seguro de que quieres bloquear el número ${v.phone}?`
      );
      setVariant("delete");
      setDialogActionText("Bloquear");
    } else {
      setVariant("contained");
      setDialogTitle(
        `¿Estás seguro de que quieres activar el número ${v.phone}?`
      );
      setDialogActionText("Activar");
    }
    setDialog(true);
  };

  const onChangedActiveBlock = async () => {
    setDialogLoading(true);
    const res = await new TableService().updateSIM(
      table?.phone,
      table?.sim_active
    );

    if (res.code === "success") {
      if (table?.sim_active) {
        snackbar.success(`El número ${table?.phone} ha sido bloqueado`);
      } else {
        snackbar.success(`El número ${table?.phone} ha sido activado`);
      }
      setTables(
        tables.map((row) =>
          row.phone === table?.phone
            ? { ...row, sim_active: !table?.sim_active }
            : row
        )
      );
    } else {
      if (res.code === "logout") {
        blocAuth.logout();
      }
    }
    setDialogLoading(false);
    setDialog(false);
    setSearch(search);
  };

  const usedGB = (val?: string) => {
    const v = Number(val);
    let result = "";

    if (v) {
      if (v > 1) {
        result = `${v.toFixed(2)} GB`;
      } else {
        const mb = v * 1000;
        if (mb >= 1) {
          result = `${mb.toFixed(2)} MB`;
        } else {
          const kb = mb * 1000;
          if (kb > 1) {
            result = `${kb.toFixed(2)} KB`;
          } else {
            result = `${kb.toFixed(2)} B`;
          }
        }
      }
    }
    return result;
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      tables
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, tables]
  );

  useEffect(() => {
    setPage(0);
    setTables(data);
    setRowsTotal(data.length);
  }, [data]);

  useEffect(() => {
    // Filter data based on search term
    const filteredData = data.filter((item) => item.phone?.includes(search));

    setTables(filteredData);
    setRowsTotal(filteredData.length);
  }, [search]);

  return (
    <ContainerContent loading={state.loading}>
      {data.length !== 0 ? (
        <Box marginBottom={2}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              className={classes.inputBase}
              onChange={handleChangeSearch}
              placeholder={"Buscar"}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Box>
      ) : null}

      {visibleRows.length !== 0 ? (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer component={Paper} sx={{ padding: 0, margin: 0 }}>
            <TablePage
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={tables.length}
              />
              <TableBody>
                {visibleRows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">{row.iccid}</TableCell>
                    <TableCell component="th" scope="row">
                      <Moment format="DD/MM/YYYY">{row.activated_at}</Moment>
                    </TableCell>
                    <TableCell align="center">
                      {Number(row.total_gb) > 0 ? `${row.total_gb} GB` : ""}
                    </TableCell>
                    <TableCell align="center">{usedGB(row.used_gb)}</TableCell>
                    <TableCell align="center">
                      {Number(row.total_minutes) > 0
                        ? `${row.total_minutes} Min`
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      {Number(row.used_minutes) > 0
                        ? `${row.used_minutes} Min`
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      {Number(row.total_sms) > 0 ? `${row.total_sms} SMS` : ""}
                    </TableCell>
                    <TableCell align="center">
                      {Number(row.used_sms) > 0 ? `${row.used_sms} SMS` : ""}
                    </TableCell>
                    <TableCell align="center">
                      {row.sim_active === true ? (
                        <Chip
                          label={"Activo"}
                          color="success"
                          variant="filled"
                        />
                      ) : (
                        <Chip
                          label={"Bloqueado"}
                          color="error"
                          variant="filled"
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.sim_active === true ? (
                        <IconButton onClick={() => handleActiveBlock(row)}>
                          <BlockIcon color="disabled" />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => handleActiveBlock(row)}>
                          <CheckIcon color="success" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TablePage>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500]}
            component="div"
            count={rowsTotal}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={"Filas por página"}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            //ActionsComponent={TablePaginationActions}
          />
        </Paper>
      ) : (
        <Empty
          title={search.length > 0 ? "Datos no encontrados" : "Sin datos"}
        />
      )}
      <ConfirmDialog
        setOpen={() => setDialog(false)}
        open={dialog}
        variant={variant}
        actionText={dialogActionText}
        title={dialogTitle}
        onClick={onChangedActiveBlock}
        actionLoading={dialogLoading}
      />
    </ContainerContent>
  );
};

export default EnhancedTable;