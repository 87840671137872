import Container from "src/components/Container";
import { useState, useEffect, useMemo } from "react";
import { UiDialog } from "src/components/Dialog";
import Content from "./components/Content";
import { Box, Container as ContainerDialog } from "@mui/material";
import { User } from "src/core/models/user_model";
import { useUserBloc } from "src/core/provider/Provider";
import UserList from "src/admin/pages/user/components/UserList";
import UiButton from "src/components/Buttons";
import AddContent from "./components/AddContent";

const UserContent = () => {
  const bloc = useUserBloc();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [addDialog, setAddDialog] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [search, setSearch] = useState<string>("");

  const initData = () => {
    if (bloc.users.length === 0) {
      bloc.getUsers();
    }
  };

  const handleEdit = (v: User) => {
    setUser(v);
    setOpenDialog(true);
  };

  const handleAdd = () => {
    setAddDialog(true);
  };

  const handleChangeSearch = (v: any) => {
    setSearch(v.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      bloc.searchUsers(search);
    }, 700);
    return () => clearTimeout(timeoutId);
  }, [search, 700]);

  useEffect(() => {
    initData();
  }, []);

  return (
    <Container
      helmetTitle="Usuarios"
      title="Usuarios"
      search
      placeholderSearch="Buscar usuario por email"
      searchOnChange={handleChangeSearch}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        justifyItems="center"
        style={{
          width: "100%",
        }}
      >
        <UiButton text="Agregar usuario" onClick={handleAdd} />
      </Box>
      <UserList bloc={bloc} onEdit={handleEdit} />
      <UiDialog open={openDialog} title={"Detalles del usuario"} maxWidth="lg">
        <ContainerDialog className="dialog-container">
          <Content setOpen={(v: boolean) => setOpenDialog(v)} data={user} />
        </ContainerDialog>
      </UiDialog>
      <UiDialog open={addDialog}>
        <AddContent setOpen={() => setAddDialog(false)} bloc={bloc} />
      </UiDialog>
    </Container>
  );
};

export default UserContent;
