import LineService from "src/core/services/line_service";
import { Bloc } from "react-stream-bloc";
import { LineInitialState, LineState } from "./line_state";
import { Line } from "src/core/models/line_model";

export class LineBloc extends Bloc<LineState> {
  lines: Line[] = [];

  constructor(private service: LineService) {
    super(LineInitialState);
    this.getLines();
  }

  async getLines() {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.getLines();

    if (response.code === "success") {
      this.changeState(this.mapToLoadedState(response.data ?? []));
    } else {
      this.changeState(this.mapToLoadedState(this.lines));
    }
  }

  async resetLines(): Promise<string | undefined> {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.resetLines();

    if (response.code === "success") {
      this.getLines();
    } else {
      this.changeState({ ...this.state, loading: false });
    }

    return response.code;
  }

  mapToLoadedState(data: Line[]): LineState {
    this.lines = data;
    return {
      loading: false,
      adding: false,
      deleting: false,
      updating: false,
      hasMore: false,
      data: data,
    };
  }
}
